import Loading from "./components/Loading.vue";
import WhiteBoard from "./components/WhiteBoard.vue";
import SettingPanel from "./components/SettingPanel";
import InteractionPanel from "./components/InteractionPanel";
import TeacherPlayer from "./components/TeacherPlayer";
import FunctionPanel from "./components/FunctionPanel";
import ModalPanel from "./components/ModalPanel";
import HorseLamp from "./components/HorseLamp";
import DocList from "./components/DocList";
import language from "./language/main.js";
import Interaction from "./components/Interaction.vue";
import { helpers } from "./utils/helpers";

const eventEmitter = BJY.eventEmitter;
const auth = BJY.auth;
const store = BJY.store;
const config = BJY.config;

eventEmitter.OPEN_SETTING_DIALOG_TRIGGER = "open_setting_dialog";

export default {
  components: {
    Loading,
    WhiteBoard,
    SettingPanel,
    InteractionPanel,
    TeacherPlayer,
    FunctionPanel,
    HorseLamp,
    ModalPanel,
    DocList,
    Interaction,
  },
  data() {
    return {
      loaded: false,
      webrtc: 1,
    };
  },
  mounted() {
    helpers.getUrlQuery();
    helpers.fixFlash(this);
  },
  created() {
    // 绑定事件
    let $body = $("body");

    eventEmitter
      .one(eventEmitter.CLASSROOM_CONNECT_TRIGGER, (event, data) => {
        // 初始化扩展
        var extension = BJY.getExtension();
        // 如果您使用的是webrtc拓展的话，请确保引入了realtimecat.js这个依赖，否则此处会报undefined：RTCat
        extension.init().then(() => {
          eventEmitter.trigger(eventEmitter.CLASSROOM_CONNECT_TRIGGER, data);
        });
        return false;
      })
      .on(eventEmitter.CLASSROOM_CONNECT_FAIL, () => {
        alert(language.ROOM_SERVER_CONNECT_FAIL);
      })
      .on(eventEmitter.LOGIN_CONFLICT, () => {
        alert(language.LOGIN_CONFLICT);
      })
      // 监听初始化事件，初始化组件
      // 请将所有的组件创建逻辑写于此回调函数之中
      .one(eventEmitter.VIEW_RENDER_TRIGGER, () => {
        // 注意，在VIEW_RENDER_TRIGGER事件触发后再去加载教室里面的组件
        this.loaded = true;

        eventEmitter.trigger(eventEmitter.DOC_ALL_REQ);

        store.set("class.speakState", config.SPEAK_STATE_LIMIT);

        if (auth.isPresenter() && store.get("class.started")) {
          setTimeout(() => {
            var player = BJY.Player.instances[BJY.store.get("user.id")];
            BJY.userPublish.setDevice(player, !player.videoOn, !player.audioOn);
          }, 1000);
        }

        $body.addClass("student");

        // 初始化webRTC的屏幕扩展
        // 如果你需要使用到webrtc屏幕共享的话，记得在index.html里面引入对应的js依赖
        if (auth.isWebRTC()) {
          BJY.ScreenShareExtensionWebrtc.init();
        }

        // 转播
        if (BJY.is.isMixWebRTC() && !!store.get("partner.liveRoomBroadcast")) {
          var TransferClass = BJY.TransferClass;
          var transferClass = new TransferClass();
          transferClass && transferClass.get();
        }
      })
      .one(eventEmitter.LOADING_PANEL_END, () => {
        // 进度条加载完成再显示ui
        $("#main").css("opacity", 1);
      })
      .on(eventEmitter.CLASS_END, () => {
        // 下课后所有人自动关闭音视频
        eventEmitter.trigger(eventEmitter.MEDIA_SWITCH_TRIGGER, {
          audioOn: false,
          videoOn: false,
        });
      })
      .on(eventEmitter.CLASS_START, () => {
        // 老师上课自动打开音视频
        if (auth.isPresenter()) {
          setTimeout(() => {
            var player = BJY.Player.instances[BJY.store.get("user.id")];
            BJY.userPublish.setDevice(player, !player.videoOn, !player.audioOn);
          }, 1000);
        }
      })
      .on(eventEmitter.CLASSROOM_CONNECT_SUCCESS, (event, data) => {
        // data.reconnect 是否为断线重连
      })
      // 踢出教室
      .on(eventEmitter.COMMAND_RECEIVE, (event, data) => {
        if (
          data.command &&
          data.command.command_type === "logout" &&
          data.toId === store.get("user.id")
        ) {
          alert("你已被踢出教室");
          location.reload();
        }
      });

    // 初始化教室
    this.init();
  },
  methods: {
    init() {
      // roomId=22032940245951
      // userName=赵金添
      const { roomId, userName } = helpers.getUrlQuery();

      console.log(roomId, userName, "==");

      const options = {
        prefixName: "tiansujing",
        env: "production",
        room_id: roomId,
        user_number: "0",
        user_avatar: "//img.baijiayun.com/0bjcloud/live/avatar/v2/teacher.png",
        user_name: userName,
        user_role: 0,
        webrtc: 1,
      };

      const data = {
        room_id: options.room_id,
        user_avatar: options.user_avatar,
        user_name: options.user_name,
        user_number: options.user_number,
        user_role: options.user_role,
        partner_key:
          "iwmhSeR4Xx1rQVK2UkuAphFDER7QELGyLTPMWIdyzDumkVjic4OdcL5QkODvLIUsWuYx2NeIaWI3Qd2UggYwRkeFcn+6",
      };

      const classOption = {
        id: options.room_id,
        speakState: BJY.config.SPEAK_STATE_LIMIT,
      };

      if (Number(options.webrtc)) {
        classOption.webrtcType = Number(options.webrtc);
      }

      this.webrtc = classOption.webrtcType;

      BJY.init({
        env: options.env,
        privateDomainPrefix: options.prefixName,
        class: classOption,
        user: {
          number: options.user_number,
          avatar: decodeURIComponent(options.user_avatar),
          name: decodeURIComponent(options.user_name),
          type: Number(options.user_role),
        },
        teacher: {
          number: options.user_number,
          avatar: decodeURIComponent(options.user_avatar),
          name: decodeURIComponent(options.user_name),
          type: Number(options.user_role),
        },
        sign: helpers.sign(options),
      });
    },
  },
};
