<!-- 抢答器组件 -->
<template>
    <div id="answer-racer-student">
        <div class="placeholder"></div>
    </div>
</template>

<script>
const auth = BJY.auth

export default {
    components: {},
    data() {
        return {
        }
    },
    methods: {
    },
    created() {
    },
    mounted() {
        if (!auth.isTeacher()) {
            BJY.AnswerRacerStudent.create({
                element: $('#answer-racer-student .placeholder')
            })
        }
    },
    beforeDestroy() { }
}
</script>

<style lang="scss">
//@import url(); 引入公共css类
/* 抢答器（学生端）样式 */
#answer-racer-student {
    left: 0;
    top: 0;
    z-index: 1000;
}
</style>
