import md5 from "md5";
import qs from "query-string";

const PARTNER_KEY =
  "iwmhSeR4Xx1rQVK2UkuAphFDER7QELGyLTPMWIdyzDumkVjic4OdcL5QkODvLIUsWuYx2NeIaWI3Qd2UggYwRkeFcn+6";

export const helpers = {
  fixFlash(context) {
    /**
     * flash的奇怪特性（普通大班课使用了flash）；
     * 需要在页面中插入一个大尺寸的flash才会自动播放，否则将会导致无法上麦和视频无法自动播放的问题
     */
    var url =
      "//live-cdn.baijiayun.com/js-sdk/1.15.0/player/extension/flash.swf";
    var html = BJY.createSWF("temp", "", url);

    var swf = $(html);
    $("body").prepend(swf);
    swf.css({
      position: "absolute",
      top: 0,
      width: 398,
      height: 298,
      opacity: 0,
    });
    // 判断是否支持flash
    if (!this.supportsFlash() && !context.webrtc) {
      context.$Dialog
        .show({
          title: "提示",
          content: `网页Flash插件加载不成功，请重新<a class="opt-button"
            href="http://www.adobe.com/go/getflashplayer">启用flash</a>`,
          confirmText: "关闭",
        })
        .then(() => {
          console.log("flash启用");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  supportsFlash() {
    var swf;
    var plugins = navigator.plugins;

    if (plugins && plugins.length > 0) {
      swf = plugins["Shockwave Flash"];
    } else if (document.all) {
      // eslint-disable-next-line no-undef
      swf = new ActiveXObject("ShockwaveFlash.ShockwaveFlash");
    }
    return !!swf;
  },
  sign(params) {
    const data = {
      room_id: params.room_id,
      user_avatar: params.user_avatar,
      user_name: params.user_name,
      user_number: params.user_number,
      user_role: params.user_role,
      partner_key: PARTNER_KEY,
    };

    return md5(
      Object.keys(data)
        .map((key) => `${key}=${data[key]}`)
        .join("&")
    );
  },
  getUrlQuery() {
    return qs.parse(window.location.search);
  },
};
