<!-- 抢答器组件 -->
<template>
    <div v-show="visible">
        <div class="close-bar">
            <span @click="close" class="bjy-close">X</span>
        </div>
        <div id="answer-racer-teacher">
            <div class="placeholder"></div>
        </div>
    </div>
</template>

<script>
import language from "../../language/main";
const eventEmitter = BJY.eventEmitter;
const auth = BJY.auth;

export default {
    components: {},
    data() {
        return {
            language,
            visible: false,
        };
    },
    methods: {
        open() {
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
    },
    created() {
        eventEmitter
            .on("toggle_answer_racer", () => {
                this.visible ? this.close() : this.open();
            })
            .on(eventEmitter.SHOW_COMMON_INFO_TRIGGER, (event, data) => {
                this.$Toast(data.message)
            })
            .on(eventEmitter.ANSWER_RACER_START, function () {
                // 抢答开始
            })
            .on(eventEmitter.ANSWER_RACER_END, function () {
                // 抢答结束
            })
    },
    mounted() {
        if (auth.isTeacher()) {
            BJY.AnswerRacerTeacher.create({
                element: $("#answer-racer-teacher .placeholder"),
                replace: true,
                skin: 'light',
                maxWaitSecondAfterStarted: 30,
                onClose: () => {
                    this.close();
                }
            })
        }
    },
    beforeDestroy() { },
};
</script>

<style lang="scss">
//@import url(); 引入公共css类
/*抢答器样式*/
#answer-racer-teacher {
    background-color: #fff;
    width: 400px;
    height: 196px;
}

.close-bar {
    background: #232323;
    height: 30px;
    text-align: right;

    .bjy-close {
        line-height: 30px;
        color: #fff;
        font-size: 20px;
        margin-right: 16px;
        cursor: pointer;
    }
}
</style>
